export const celoPools = [
  {
    id: 'celo-tosha',
    logo: 'single-assets/Tosha.png',
    name: 'TOSHA Celo',
    token: 'ORANGE',
    tokenDescription: 'tosha',
    tokenAddress: '0x50359e800aCde1D93BB673E235Bafb86D0846c14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ToshaLP',
    earnedTokenAddress: '0x9aB7Cd67dDb8e56a53bd76622448149F01b7CD20',
    earnContractAddress: '0x9aB7Cd67dDb8e56a53bd76622448149F01b7CD20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ORANGE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'tosha.io',
    assets: ['ORANGE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x50359e800aCde1D93BB673E235Bafb86D0846c14',
  },
];
