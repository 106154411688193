import { govPoolABI } from '../abi';

const beefy = {
  logo: 'stake/beefy/beefyfinance.png',
  logoNight: 'stake/beefy/beefyfinance_night.png',
  background: 'stake/beefy/background.png',
  text: "Beefy Finance is The Multi-Chain Yield Optimizer across many blockchains, enabling users to earn autocompounded yield on their crypto. Did you know also that you can own a piece of Beefy itself? Beefy runs on its governance token, BIFI. The token has a set supply of 80,000 across all chains; no more may be minted, ever! As a holder of BIFI you may create and vote on important DAO proposals, and you become dividend-eligible to earn a share of every compounding harvest on Beefy vaults, hour by hour. Here on Avalanche, you just need to stake BIFI in this reward pool, or in the autocompounding BIFI Maxi vault on the main page. For this pool, AVAX dividends are gathered and sent proportionally to each staker. Stake here, return later to claim the AVAX you've earned.",
  website: 'https://app.beefy.finance',
  social: {
    telegram: 'http://t.me/beefyfinance',
    twitter: 'https://twitter.com/beefyfinance',
  },
};

export const avalancheStakePools = [
  {
    id: 'bifi-avax',
    name: 'BIFI',
    logo: 'single-assets/BIFI.png',
    token: 'BIFI',
    tokenDecimals: 18,
    tokenAddress: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'AVAX',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    earnContractAddress: '0x86d38c6b6313c5a3021d68d1f57cf5e69197592a',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WAVAX',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    partners: [beefy],
  },

  {
    id: 'moo_curve-avax-atricrypto-ripae',
    name: 'Ripae',
    logo: 'uncategorized/ATRICRYPTO.png',
    token: 'mooCurveTriCrypto',
    tokenDecimals: 18,
    tokenAddress: '0xe1a8EeA58D63Ea64d00365531D266C2AD1f62FC4',
    tokenOracle: 'lps',
    tokenOracleId: 'curve-avax-atricrypto',
    earnedToken: 'PAE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9466Ab927611725B9AF76b9F31B2F879Ff14233d',
    earnContractAddress: '0xc4C291b76F9aD94F0Ce57E7a5E629D81A02bB1C9',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'PAE',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1648121946,
    partners: [
      {
        logo: 'stake/ripae/logo.png',
        background: 'stake/ripae/bg.png',
        text:
          'Ripae’s mission is to construct a cross-chain algorithmic stablecoin (ASC) economy and connect DeFi networks to make open finance capital efficient and accessible to everyone.\n' +
          '\n' +
          'With its experienced team, Ripae Finance will strive to build DeFi’s most convenient, secure, fair, and cost-effective ASC solution in order to help the industry thrive by enabling all participants to work together effortlessly.',
        website: 'https://avax.ripae.finance/',
        social: {
          telegram: 'https://discord.gg/6zq53FB4TS',
          twitter: 'https://twitter.com/ripaefinance',
        },
      },
    ],
  },

  {
    id: 'moo_AVAX-grape',
    name: 'Grape Finance',
    logo: 'single-assets/AVAX.svg',
    token: 'mooAaveAVAX',
    tokenDecimals: 18,
    tokenAddress: '0x1B156C5c75E9dF4CAAb2a5cc5999aC58ff4F9090',
    tokenOracle: 'tokens',
    tokenOracleId: 'AVAX',
    earnedToken: 'WINE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xC55036B5348CfB45a932481744645985010d3A44',
    earnContractAddress: '0xE1BBde90EB564c5f26dfcDeB9918BDA183BF4e2b',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WINE',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1645707476,
    partners: [
      {
        logo: 'stake/grape/logo.png',
        background: 'stake/grape/bg.png',
        text: 'Supporting the only pegged algorithmic stable currency currently on AVAX, $GRAPE which is pegged to $MIM. The goal is to create a DAO run by the community offering a high yield for investors and utilize two tokens $GRAPE and $WINE to bootstrap liquidity and provide funding for the extensive roadmap. An extensive roadmap including Launchpad, great partnerships, and upcoming lending protocol/new stable reserve currency. Future goals to support multiple chains and create a total defi ecosystem under the GrapeFi umbrella.',
        website: 'https://grapefinance.app/',
        social: {
          telegram: 'https://discord.gg/mZ4QrZwH5M',
          twitter: 'https://twitter.com/grape_finance',
        },
      },
    ],
  },

  {
    id: 'moo_bifi_avax-singular',
    name: 'Singular',
    assets: ['BIFI', 'AVAX'],
    token: 'mooJoeWAVAX-BIFI',
    tokenDecimals: 18,
    tokenAddress: '0xb1e29194d90d67b8d1c4104FDf6DaF0F7d3344D5',
    tokenOracle: 'lps',
    tokenOracleId: 'joe-wavax-bifi',
    earnedToken: 'SING',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xF9A075C9647e91410bF6C402bDF166e1540f67F0',
    earnContractAddress: '0x2554216fD346ABDBD59cc6f7E85A3fdAF15c1419',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SING',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1635096979,
    partners: [
      {
        logo: 'stake/singular/logo.png',
        background: 'stake/singular/bg.png',
        text: 'The next gen multichain strategic yield farm on Polygon (Matic Network), Binance Smart Chain (BSC), and Avalanche with a unique Triple Farming System.',
        website: 'https://singular.farm/',
        social: {
          telegram: 'https://t.me/singularfarm',
          twitter: 'https://twitter.com/singularfarm',
        },
      },
    ],
  },
  {
    id: 'moo_joe-bifi',
    name: 'Beefy',
    logo: 'single-assets/JOE.png',
    token: 'mooJoe',
    tokenDecimals: 18,
    tokenAddress: '0x282B11E65f0B49363D4505F91c7A44fBEe6bCc0b',
    tokenOracle: 'tokens',
    tokenOracleId: 'JOE',
    earnedToken: 'mooAvalancheBIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCeefB07Ad37ff165A0b03DC7C808fD2E2fC77683',
    earnContractAddress: '0x90e91cAf13F6C06fD04031cF5f398F8b3BAB794B',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1633540000,
    partners: [beefy],
  },
];
