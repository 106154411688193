export const oasisPools = [
  {
    id: 'emerald-tosha',
    logo: 'single-assets/Tosha.png',
    name: 'TOSHA OASIS',
    token: 'TOSHA',
    tokenDescription: 'Tosha',
    tokenAddress: '0x79801BFFA6699d2Be730f818e9A0ad49BBc65951',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ToshaLP',
    earnedTokenAddress: '0x178515c2a625A696783EEEdEA2EAD8600eE059bd',
    earnContractAddress: '0x178515c2a625A696783EEEdEA2EAD8600eE059bd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TOSHA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'tosha.io',
    assets: ['ORANGE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://app.yuzu-swap.com/#/swap?outputCurrency=0x79801BFFA6699d2Be730f818e9A0ad49BBc65951',
  },
  // {
  //   id: 'yuzu-wrose-weusdt',
  //   name: 'wROSE-weUSDT LP',
  //   token: 'wROSE-weUSDT LP',
  //   tokenDescription: 'Yuzuswap',
  //   tokenAddress: '0xfb9BD52Abe613A5C4A20e9fC09462A2EC5F2d1B2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'yuzu-wrose-weusdt',
  //   earnedTokenAddress: '0x051fb88ABe69Ddc2caaEB4A244517904206b94A5',
  //   earnContractAddress: '0x051fb88ABe69Ddc2caaEB4A244517904206b94A5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'yuzu-wrose-weusdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'yuzu',
  //   assets: ['wRose', 'mUSDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://app.yuzu-swap.com/#/swap?outputCurrency=0xdC19A122e268128B5eE20366299fc7b5b199C8e3&inputCurrency=0x21C718C22D52d0F3a789b752D4c2fD5908a8A733',
  //   buyTokenUrl:
  //     'https://app.yuzu-swap.com/#/swap?outputCurrency=0xdC19A122e268128B5eE20366299fc7b5b199C8e3&inputCurrency=0x21C718C22D52d0F3a789b752D4c2fD5908a8A733',
  // },
];
